const moduleGetters = {
    selectedFields: (state) => state.selectedFields,
    overlay: (state) => state.overlay,
    overlayFolded: (state) => state.overlay.folded,
    polygons: (state) => state.polygons,
    currentHeatmaps: (state) => state.heatmaps.current,
    multiPolyTimestamps: (state) => Object.values(state.multiPolyTimestamps.current),
    vegetationData: (state) => state.vegetationData.current,
    selectedHeatmapDbId: (state) => state.selectedHeatmapDbId,
};
export default moduleGetters;
