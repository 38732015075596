import { ENTRY_SYNCED, GUID_KEY } from '@/shared/constants';
import productsModuleStore from './common';
const mutations = Object.assign(Object.assign({}, productsModuleStore.mutations), { loadAll(state, { data }) {
        state.data = data.reduce((dataCurrent, entry) => {
            const material = Object.assign(Object.assign({}, entry), { storeStatus: ENTRY_SYNCED, n: 0, p: 0, k: 0 });
            if (Array.isArray(material.variants) &&
                material.variants.length > 0 &&
                material.variants[0].processOrderId == null &&
                material.variants[0].ingredients != null) {
                Object.keys(material.variants[0].ingredients).forEach((key) => {
                    // @ts-ignore
                    material[key.toLowerCase()] = material.variants[0].ingredients[key].amount;
                });
            }
            const newData = Object.assign(Object.assign({}, dataCurrent), { [entry[GUID_KEY]]: material });
            return newData;
        }, {});
        state.loaded = new Date();
    } });
export default mutations;
