import _cloneDeep from 'lodash.clonedeep';
import { isSibling } from '@/products/store/compatibleProductsFilter';
import { isDropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import { createUuid } from '@/shared/modules/uuid';
import { isAmountUnitPair } from '../types';
// TODO move to units service
import { findRelativeUnit, findTotalUnit, isRelativeUnit, isTotalUnit } from '../utils/amountsAndUnits/findUnit';
export default class ActivityProductService {
    constructor(units, productService, calculateAmountsAndUnits) {
        this.units = units;
        this.productService = productService;
        this.calculateAmountsAndUnits = calculateAmountsAndUnits; // TODO replace by AmountsAndUnitsService
    }
    updateActivityProductsFromChange(activityProducts, change, companyId) {
        const [, combinedKey, , newValue, { activityProductId }] = change;
        const key = combinedKey.split('.').slice(1).join('.');
        let activityProduct = ActivityProductService.findOrCreateActivityProduct(activityProductId, activityProducts);
        activityProduct = this.updateActivityProduct(activityProduct, key, newValue, companyId);
        const updatedActivityProducts = ActivityProductService.addOrReplaceActivityProduct(activityProduct, activityProducts);
        return updatedActivityProducts;
    }
    static createActivityProduct(id) {
        return {
            id: id !== null && id !== void 0 ? id : createUuid(),
            productId: '',
            amount: null,
            deleted: null,
            tstamp: toUnixTimestamp(Date.now()),
        };
    }
    findUnitIdWithFallback(activityProduct) {
        const { productId, unitId } = activityProduct;
        if (unitId)
            return unitId;
        if (!productId)
            return undefined;
        const product = this.productService.findProductById(productId);
        return product === null || product === void 0 ? void 0 : product.unitId;
    }
    getUnitIdWithFallback(activityProduct) {
        const unitId = this.findUnitIdWithFallback(activityProduct);
        if (!unitId)
            throw new Error(`UnitId of activityProduct ${activityProduct.id} is not set`);
        return unitId;
    }
    static findOrCreateActivityProduct(activityProductId, activityProducts) {
        if (!activityProductId) {
            return ActivityProductService.createActivityProduct();
        }
        const activityProduct = activityProducts.find((product) => product.id === activityProductId);
        if (!activityProduct) {
            return ActivityProductService.createActivityProduct(activityProductId);
        }
        return activityProduct;
    }
    updateActivityProduct(activityProduct, key, value, companyId) {
        switch (key) {
            case 'productStorageDropdownItem': {
                if (!isDropdownItem(value))
                    throw new Error(`Cannot update product and storage place with non DropdownItem value: ${value}`);
                return this.updateProductStorageAndOptionalUnit(activityProduct, value, companyId);
            }
            case 'pesticideIndicationDropdownItem': {
                if (!isDropdownItem(value))
                    throw new Error(`Cannot update pesticide indication with non DropdownItem value: ${value}`);
                return ActivityProductService.updatePestAndPesticideIndication(activityProduct, value);
            }
            case 'amountUnitRelative':
            case 'amountUnitTotal':
                if (!isAmountUnitPair(value))
                    throw new Error(`Cannot update amount and unit with non AmountUnitPair value: ${value}`);
                return this.updateAmountAndUnit(activityProduct, key, value);
            default:
                throw new Error(`Unsupported key ${key}`);
        }
    }
    updateProductStorageAndOptionalUnit(activityProduct, dropdownItem, companyId) {
        if (!dropdownItem.id)
            throw new Error('DropdownItem id is not set');
        const [productId, storagePlaceId] = dropdownItem.id.split('_');
        const { amount } = activityProduct;
        const unitId = this.getUnitId(productId);
        let updatedActivityProduct = this.setProductOrSibling(activityProduct, productId, companyId);
        updatedActivityProduct = ActivityProductService.setStoragePlaceId(updatedActivityProduct, storagePlaceId);
        updatedActivityProduct = ActivityProductService.setUnitId(updatedActivityProduct, unitId);
        updatedActivityProduct = ActivityProductService.setAmount(updatedActivityProduct, amount);
        return updatedActivityProduct;
    }
    static updatePestAndPesticideIndication(activityProduct, dropdownItem) {
        if (!dropdownItem.id)
            throw new Error('DropdownItem id is not set');
        const [pestId, pesticideIndicationId] = dropdownItem.id.split('_');
        return Object.assign(Object.assign({}, activityProduct), { pestId,
            pesticideIndicationId });
    }
    updateAmountAndUnit(activityProduct, key, value) {
        const numericValue = value.amount;
        const currentUnitId = this.getUnitIdWithFallback(activityProduct);
        const unitId = this.findUnitIdOfAmountType(key, currentUnitId);
        return Object.assign(Object.assign({}, activityProduct), { amount: numericValue, unitId });
    }
    updateUnit(activityProduct, unitId, processedArea) {
        if (!activityProduct.unitId || !activityProduct.amount) {
            return Object.assign(Object.assign({}, activityProduct), { unitId });
        }
        const amountsAndUnits = this.calculateAmountsAndUnits(activityProduct.amount, activityProduct.unitId, processedArea, this.units);
        const newUnit = this.units[unitId];
        if (isTotalUnit(newUnit)) {
            return Object.assign(Object.assign({}, activityProduct), { unitId, amount: amountsAndUnits.amountUnitTotal.amount });
        }
        if (isRelativeUnit(newUnit)) {
            return Object.assign(Object.assign({}, activityProduct), { unitId, amount: amountsAndUnits.amountUnitRelative.amount });
        }
        throw new Error(`Unsupported unit ${newUnit}`);
    }
    getAmountsAndUnits(activityProduct, processedArea) {
        const { amount } = activityProduct;
        const unitId = this.findUnitIdWithFallback(activityProduct);
        return this.calculateAmountsAndUnits(amount, unitId, processedArea, this.units);
    }
    static setStockLevelValues(activityProduct, stockLevel) {
        return Object.assign(Object.assign({}, activityProduct), stockLevel);
    }
    static addOrReplaceActivityProduct(activityProduct, activityProducts) {
        const index = activityProducts.findIndex((product) => product.id === activityProduct.id);
        if (index === -1) {
            return [...activityProducts, activityProduct];
        }
        return [...activityProducts.slice(0, index), activityProduct, ...activityProducts.slice(index + 1)];
    }
    getUnitId(productId) {
        const product = this.productService.getProductById(productId);
        if (!product.unitId)
            throw new Error(`UnitId not set for product ${productId}`);
        return product.unitId;
    }
    findUnitIdOfAmountType(key, currentUnitId) {
        var _a, _b;
        const unit = this.units[currentUnitId];
        if (!unit)
            throw new Error(`Unit ${currentUnitId} not found`);
        switch (key) {
            case 'amountUnitRelative': {
                if (isRelativeUnit(unit))
                    return currentUnitId;
                return (_a = findRelativeUnit(unit, this.units)) === null || _a === void 0 ? void 0 : _a.id;
            }
            case 'amountUnitTotal': {
                if (isTotalUnit(unit))
                    return currentUnitId;
                return (_b = findTotalUnit(unit, this.units)) === null || _b === void 0 ? void 0 : _b.id;
            }
            default:
                throw new Error(`Unsupported key ${key}`);
        }
    }
    setProductOrSibling(activityProduct, productId, companyId) {
        const product = this.productService.getProductOrSibling(productId, companyId);
        const updatedActivityProduct = _cloneDeep(activityProduct);
        updatedActivityProduct.productId = product.id;
        return updatedActivityProduct;
    }
    isSibling(activityProductA, activityProductB) {
        // eslint-disable-next-line eqeqeq
        if (activityProductA.unitId != activityProductB.unitId)
            return false;
        // eslint-disable-next-line eqeqeq
        if (activityProductA.storagePlaceId != activityProductB.storagePlaceId)
            return false;
        const productA = this.productService.findProductById(activityProductA.productId);
        const productB = this.productService.findProductById(activityProductB.productId);
        const bothProductsAreUndefined = !productA && !productB;
        const bothProductsAreDefined = !!(productA && productB);
        if (bothProductsAreUndefined || (bothProductsAreDefined && isSibling(productA, productB))) {
            return true;
        }
        return false;
    }
    //
    // ENTITY FUNCTIONS START
    // the following functions should be part of ActivityProduct entity
    //
    getCompanyId(activityProduct) {
        var _a;
        const product = this.productService.findProductById(activityProduct.productId);
        return (_a = product === null || product === void 0 ? void 0 : product.companyId) !== null && _a !== void 0 ? _a : null;
    }
    getProduct(activityProduct) {
        if (!activityProduct.productId)
            return undefined;
        return this.productService.getProductById(activityProduct.productId);
    }
    static setUnitId(activityProduct, unitId) {
        const updatedActivityProduct = _cloneDeep(activityProduct);
        // TODO check if unitId is compatible with product
        updatedActivityProduct.unitId = unitId;
        return updatedActivityProduct;
    }
    static setStoragePlaceId(activityProduct, storagePlaceId) {
        const updatedActivityProduct = _cloneDeep(activityProduct);
        // TODO check if storagePlaceId is compatible with product
        updatedActivityProduct.storagePlaceId = storagePlaceId;
        return updatedActivityProduct;
    }
    static setAmount(activityProduct, amount) {
        const updatedActivityProduct = _cloneDeep(activityProduct);
        updatedActivityProduct.amount = amount;
        return updatedActivityProduct;
    }
}
