import Vue from 'vue';
import initialState from './initialState';
const mutations = {
    setData(state, { pesticideId, pesticideIndications }) {
        Vue.set(state.data, pesticideId, pesticideIndications);
    },
    setFetching(state, fetching) {
        state.fetching = fetching;
    },
    reset(state) {
        const newState = initialState();
        Object.entries(newState).forEach(([key, value]) => {
            Vue.set(state, key, value);
        });
    },
};
export default mutations;
